<template>
	<div class="dashboard">
		<div class="container-fluid pt-100 text-center">
			<div v-if="!mobile" style="position:relative;display: inline-flex;width:70%">
				<div class="text-box">
					<span style="font-size: 35px;font-weight: bold">Oops! Page not found</span>
					<p style="margin: 15px 0">The URL may be misspelled or the page you're looking for is no longer
						available.</p>
					<b-button @click="nagigatePage" variant="secondary" size="sm" class="ml-auto w-sm-100 mt-20 mt-md-0">
						Go Home <i class="icon-arrow fs10 ml-7"></i>
					</b-button>
				</div>
			</div>
			<div v-else>
				<div>
					<span style="font-size: 20px;font-weight: bold">Oops! Page not found</span>
					<p style="margin: 10px 0">The URL may be misspelled or the page you're looking for is no longer
						available.</p>
					<b-button @click="nagigatePage" variant="secondary" size="sm" class="ml-auto w-sm-100 mt-20 mt-md-0">
						Go Home <i class="icon-arrow fs10 ml-7"></i>
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PageNotFound",
	data() {
		return {
			mobile: false,
			innerWidth: window.innerWidth,
		}
	},

	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},

	methods: {
		onResize() {
			this.innerWidth = window.innerWidth
		},
		nagigatePage() {
			const user = this.$store.getters.user;
			if (user && user?.id && user?.role == 5) {
				this.$router.push({ name: 'InScan' })
			}
			else if (user && user?.id && user?.role == 45) {
				this.$router.push({ name: 'InScanHub' });
			}
			else if (user && user?.id && user?.role == 56) {
				this.$router.push({ name: 'ImportOriginScan' });
			}
			else {
				this.$router.push('/login');
			}
		}
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	watch: {

		innerWidth(newValue) {

			this.size = newValue;

			if (newValue < 700) {
				this.mobile = true
			}
			else {
				this.mobile = false
			}

		}
	}

}

</script>

<style scoped>
.text-box {
	position: absolute;
	color: #555;
	top: 150px;
	right: 0px;
	height: 113px;
	width: 100%;
	text-align: center;
}
</style>
